(function($) {
  instagramViewer = function() {
    $instagramViewers = $('.instagram-viewer-js');
    $.each($instagramViewers, function(index, value) {
        var target = $(value).attr('id');
        var accessToken = $(value).data('access-token');
        var userId = $(value).data('user-id');
        var limit = $(value).data('limit');

        console.log(target,accessToken,userId,limit);

        var feed = new Instafeed({
            get: 'user',
            userId: userId,
            accessToken: accessToken,
            target: target,
            resolution: 'standard_resolution',
            limit: limit,
            template: '<div class="instagram-post"><img src="{{image}}" /><div class="caption">{{caption}}</div><a href="{{link}}" class="link" target="_blank"></a></div>'
        });
        feed.run();
    });
    
  };
})(jQuery);
