/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation(); // Foundation JavaScript

        //init webnerds functions
        // stickyHeader();
        mobileNav();
        autoScrollToAnchor();
        siteHeaderSubMenu();
        questionsAndAnswers();
        gallery();
        instagramViewer();
        stickyHeader();

        $(".flexible-content .news-list").imagesLoaded(function() {
            $('.flexible-content .news-list').masonry({
              itemSelector: '.news-item'
            });
        });

        if(window.location.href.indexOf("mc4wp") > -1) {
            setTimeout(
              function()
              {
                  $('html, body').animate({
                    scrollTop: $('.email-signup').offset().top
                }, 500, 'linear');
            }, 1000);
        }

        $('.list-cases .case a').on("touchstart",function(e){
              e.preventDefault();

              $(this).find('.overlay-button').on("touchstart",function(e){
                 window.location.href =  $(this).parents('a').data('link');
              });
        });

        if(Foundation.MediaQuery.atLeast('medium')) {
            $('.team-item').each(function() {
                var $teamItemHeight = $(this).find('.team-details').height();
                $(this).find('.team-image').height($teamItemHeight);
            });

            $(window).resize(function() {
                $('.team-item').each(function() {
                    var $teamItemHeight = $(this).find('.team-details').height();
                    $(this).find('.team-image').height($teamItemHeight);
                });
            });

            var $ctaHeight = $('.cta-twocol-item').outerHeight();
            $('.page-banner').css({ 'height': 'calc(100vh - ' + $ctaHeight + 'px)'});

        }

        $(window).resize(function() {
            if(Foundation.MediaQuery.atLeast('medium')) {
                $ctaHeight = $('.cta-twocol-item').outerHeight();
                $('.page-banner').css({ 'height': 'calc(100vh - ' + $ctaHeight + 'px)'});
            } else {
                $('.page-banner').css({'height': ''});
            }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.page-banner .down-arrow').click(function(e){
          var bannerHeight = jQuery('.page-banner').outerHeight();
          var headerHeight = jQuery('header').height();
          $('html, body').animate({ scrollTop: (bannerHeight - headerHeight)}, 400);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'team': {
      init: function() {
        $('.coworkers-list .profile-link-open').click(function(e){
          var target = $(e.currentTarget).attr('data-revealid');
          $('#'+target).parent().fadeIn();
          $('html, body').addClass('overflow-hidden');
        });
        $('.profile-link-close').click(function(e){
          var target = $(e.currentTarget).attr('data-revealid');
          $('#'+target).parent().fadeOut();
          $('html, body').removeClass('overflow-hidden');
        });
      }
    },
    'blog': {
      init: function() {

          var alm_is_animating = false; // Animating flag

          //Mobile
          $('.filter select').change(function() {
              var el = $(this).find(':selected'); // Our selected element

              var data = el.data(), // Get data values from selected menu item
                     transition = 'fade', // 'slide' | 'fade' | null
                     speed = '300'; //in milliseconds

              $.fn.almFilter(transition, speed, data); // Run the filter

          });

          //Desktop
          $('.alm-filter-nav li').eq(0).addClass('active'); // Set initial active state

          // Btn Click Event
          $('.alm-filter-nav li a').on('click', function(e){
              e.preventDefault();
              var el = $(this); // Our selected element

              if(!el.hasClass('active') && !alm_is_animating){ // Check for active and !alm_is_animating
                 alm_is_animating = true;
                 el.parent().addClass('active').siblings('li').removeClass('active'); // Add active state

                 var data = el.data(), // Get data values from selected menu item
                     transition = 'fade', // 'slide' | 'fade' | null
                     speed = '300'; //in milliseconds

                 $.fn.almFilter(transition, speed, data); // Run the filter
              }
          });

          $.fn.almFilterComplete = function(){
              alm_is_animating = false; // clear animating flag
          };


      }
    },
    finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
