(function($) {
mobileNav = function() {
    $('.site-header .mobile-nav-toggle').click(function(){
        $('body').toggleClass('mobile-nav-open');
    });

    $('.site-header .mobile-nav-toggle').click(function(){
		$('.site-header').addClass('mobile-nav-opened');

		if($('body').hasClass('mobile-nav-open')) {
			$('body').removeClass('mobile-nav-closed');
		} else {
			$('body').addClass('mobile-nav-closed');
		}
    });

    $('.sidebar-section .mobile-sidebar-toggle').click(function(){
        $('body').toggleClass('mobile-sidebar-open');
    });

    $(window).resize(function() {
        $('body').removeClass('mobile-nav-open');
    });

};
})(jQuery);
